<script lang="ts" setup>
import { EffectFade, Autoplay, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/vue"
import SwiperCore from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import type { BannerList } from "@/utils/types"

const props = defineProps({
  data: {
    type: Array as PropType<BannerList[]>,
    default: [],
  },
})

SwiperCore.use([Autoplay, Pagination])

const currentIndex: Ref<number> = ref(0)

const swiperSlideSelector = ref()
const onSwiper = (data: any) => {
  //存放 swiper
  swiperSlideSelector.value = data
}

const swiperTo = (index: number) => {
  swiperSlideSelector.value.slideTo(index)
}

const handelSwiperChange = (data: any) => {
  currentIndex.value = data.realIndex
}

const handleSlidePrev = () => {
  currentIndex.value = currentIndex.value - 1
  if (currentIndex.value < 0) currentIndex.value = props.data.length - 1
  swiperTo(currentIndex.value)
};
const handleSlideNext = () => {
  currentIndex.value = currentIndex.value + 1
  if (currentIndex.value >= props.data.length) currentIndex.value = 0
  swiperTo(currentIndex.value)
};
</script>

<template>
  <section class="banner">
    <swiper :autoplay="{
      delay: 3000,
    }" :slides-per-view="1" :space-between="0" :speed="300" :loop="true" :modules="[EffectFade]" :pagination="true"
      effect="fade" class="swiper-list" @swiper="onSwiper" @slide-change="handelSwiperChange">
      <swiper-slide v-for="(item, index) in data" :key="index">
        <div class="swiper-list__box">
          <nuxt-link :to="item.link" target="_blank">
            <figure class="swiper-list__pic">
              <NuxtImg :src="item.image" :alt="item.titleEn"></NuxtImg>
            </figure>
            <div class="swiper-list__info">
              <div class="swiper-list__titleEn">{{ item.titleEn }}</div>
              <div class="swiper-list__titleTw">{{ item.titleTw }}</div>
            </div>
          </nuxt-link>
        </div>
      </swiper-slide>
    </swiper>
    <div class="banner__arrow">
      <button class="buttonReset banner__arrow__button prev" @click="handleSlidePrev">
        <svg-icon name="icon-arrow-right3" />
      </button>

      <button class="buttonReset banner__arrow__button next" @click="handleSlideNext">
        <svg-icon name="icon-arrow-right3" />
      </button>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.swiper-list {
  &__box {
    position: relative;
    width: 100%;
    padding-top: 33%;
    min-height: 250px;

    &:hover {
      .swiper-list {
        &__pic {
          img {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &__pic {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s ease-in-out;
    }
  }

  &__info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 35px;
    padding-bottom: 30px;
    color: #fff;

    @include max-media(960) {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding-left: 45px;
      padding-right: 45px;
      padding-bottom: 0;
      text-align: center;
    }
  }

  &__titleEn {
    margin-bottom: 3px;
    font-family: $font-NotoSans;
    font-size: 12.6px;
    line-height: 1.07;
    opacity: 0;
    transform: translateY(12px);
    transition: all 0.8s ease-in-out;

    @include max-media(480) {
      font-size: 11px;
    }
  }

  &__titleTw {
    font-size: 18px;
    font-weight: 500;
    opacity: 0;
    transform: translateY(12px);
    transition: all 0.8s ease-in-out;

    @include max-media(480) {
      font-size: 11px;
    }
  }

  .swiper-slide-active {
    .swiper-list {
      &__titleEn {
        opacity: 1;
        transform: translateY(0);
      }

      &__titleTw {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.3s;
      }
    }
  }
}

.banner {
  position: relative;

  &__arrow {
    &__button {
      position: absolute;
      top: 50%;
      margin-top: -26px;
      z-index: 50;
      width: 33px;
      height: 52px;
      color: #fff;

      svg {
        display: block;
        width: 100%;
        height: 100%;
        transform: scale(0.85);
      }

      &.prev {
        left: 30px;
        transform: scaleX(-1);

        @include max-media(767) {
          left: 15px;
        }
      }

      &.next {
        right: 30px;

        @include max-media(767) {
          right: 15px;
        }
      }
    }
  }
}

:deep(.swiper-pagination-bullet) {
  background: #fff;
  opacity: 1;
}

:deep(.swiper-pagination-bullet-active) {
  background: #000;
  margin: 0 11px !important;
}
</style>
