<script lang="ts" setup>
import type { IHomePopup } from '~/api/page'
const props = defineProps({
  data: {
    type: Object as PropType<IHomePopup>,
    default: {},
  },
  type: {
    type: String,
    default: "image", // text, image
  },
});

const isShow = ref();

onMounted(() => {
  const type = sessionStorage.getItem("popupAd");
  if (type === null) {
    isShow.value = true;
    return;
  }

  isShow.value = false;
});

const handelClose = () => {
  sessionStorage.setItem("popupAd", "false");
  isShow.value = false;
};

const storageUrl = import.meta.env.VITE_STORAGE_URL;
</script>

<template>
  <section class="dialog-cover" v-show="isShow">
    <div class="dialog-cover__mask" @click="handelClose"></div>
    <div class="dialog-cover__inner" :class="`type-${props.type}`">
      <div class="dialog-cover__content">
        <div class="dialog-cover__close" @click="handelClose">
          <button class="buttonReset buttonDelete" @click="handelClose"></button>
        </div>
        <div class="dialog-cover__image">
          <NuxtImg :src="`${storageUrl}/${data.image}`"></NuxtImg>
        </div>
        <!-- <div class="dialog-cover__title">
          fancy zone • 恆星之夜
        </div>
        <div class="dialog-cover__text">
          花器只能用買的嗎?
        </div> -->
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.dialog-cover {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &__mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black-1, 0.8);
    cursor: pointer;
  }

  &__inner {
    position: relative;
    z-index: 1;
    background-color: $color-white-2;

    img {
      display: block;
      width: 100%;
    }

    &.type-text {
      width: 100%;
      max-width: 560px;
      padding: 56px;
    }

    &.type-image {
      width: 100%;
      max-width: 500px;
      max-height: 90vh;
      overflow-y: auto;

      @include max-media(768) {
        width: 80%;
      }
    }
  }

  &__title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    color: $color-black-1;
  }

  &__text {
    font-size: 16px;
    color: $color-gray-1;
    line-height: 1.45;
  }

  &__close {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    .buttonDelete {
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -8px;
        margin-top: -0.5px;
        width: 20px;
        height: 2px;
        background-color: #000;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__cancel {
      background: none;

      :deep(.button__title) {
        font-weight: 400;
        font-size: 14px;
        color: $color-gray-1;
      }

      :deep(.button__icon) {
        color: $color-gray-1;
      }
    }
  }
}
</style>
