<script lang="ts" setup>
import Section1 from "./components/Section1.vue"
import Section2 from "./components/Section2.vue"
import Section3 from "./components/Section3.vue"
import Section4 from "./components/Section4.vue"
import Section5 from "./components/Section5.vue"
import Section6 from "./components/Section6.vue"
import Section7 from "./components/Section7.vue"
import Section8 from "./components/Section8.vue"
import type { BannerList, Card3List, Card4List, Card5List, NewsList, Section1List } from '@/utils/types/List'
import useSeo from "./seo"

const { page } = useApi()
const { data, pending, execute } = await page.getHomePageInfo()
const pageInfo = data.value?.data
const banners = computed(() => (pageInfo?.banners ? pageInfo?.banners.map((item) => {
  return {
    image: `${import.meta.env.VITE_STORAGE_URL}/${item.image}`,
    titleEn: item.en_title,
    titleTw: item.title,
    link: item.url,
  }
}) : []) as BannerList[])

const firstBlock = computed(() => (pageInfo?.first_block ? pageInfo?.first_block.map((item) => {
  return {
    image: `${import.meta.env.VITE_STORAGE_URL}/${item.image}`,
    titleEn: item.en_title,
    titleTw: item.title,
    link: item.url,
  }
}) : []) as Section1List[])

const newsBlock = computed(() => (pageInfo?.reports ? pageInfo?.reports.map((item) => {
  return {
    image: `${import.meta.env.VITE_STORAGE_URL}/${item.image}`,
    title: item.title.replace('<br />', ' '),
    link: `/news/${item.seo?.meta_slug || item.id}`,
  }
}) : []) as NewsList[])

const storyBlock = computed(() => (pageInfo?.stories ? pageInfo?.stories.map((item) => {
  const type = item.type.toLocaleLowerCase() + '-stories'
  return {
    image: `${import.meta.env.VITE_STORAGE_URL}/${item.image}`,
    titleEn: item.en_title,
    titleTw: item.title,
    link: `/${type}/${item.seo?.meta_slug || item.id}`,
  }
}) : []) as Card3List[])

const opinionBlock = computed(() => (pageInfo?.opinions ? pageInfo?.opinions.map((item) => {
  return {
    image: `${import.meta.env.VITE_STORAGE_URL}/${item.image}`,
    titleEn: item.en_title,
    titleTw: item.title,
    textEn: item.en_description,
    textTw: item.description,
    link: `/opinion/${item.seo?.meta_slug || item.id}`,
  }
}) : []) as Card4List[])

const issueBlock = computed(() => (pageInfo?.issues ? pageInfo?.issues.map((item) => {
  let type = item.type.toLocaleLowerCase()
  if (type === 'iw') type = 'issue'
  if (type === 'detail') type = 'issue-detail'
  return {
    id: item.id,
    image: `${import.meta.env.VITE_STORAGE_URL}/${item.image}`,
    title: item.title,
    price: item.price,
    link: `/${type}/${item.seo?.meta_slug || item.id}`,
  }
}) : []) as Card5List[])

if (data.value?.data?.seo) {
  useSeo(data.value?.data?.seo)
}
</script>

<template>
  <section class="home">
    <DialogCover :data="pageInfo?.popup" v-if="pageInfo?.popup?.image && pageInfo?.popup_is_visible" />
    <div class="home__banner">
      <BannerSlide :data="banners" />
    </div>
    <Section1 :data="firstBlock" />
    <Section2 v-if="pageInfo?.ad_first && pageInfo?.ad_first.length > 0" :data="pageInfo?.ad_first" />
    <Section3 v-if="pageInfo?.reports_is_visible" :data="newsBlock" />
    <Section4 v-if="pageInfo?.stories_is_visible" :data="storyBlock" />
    <Section5 v-if="pageInfo?.opinions_is_visible" :data="opinionBlock" />
    <Section6 v-if="pageInfo?.issues_is_visible" :data="issueBlock" />
    <Section7 v-if="pageInfo?.exhibition_is_visible && pageInfo?.exhibition" :data="pageInfo?.exhibition" />
    <AdAd4 v-if="pageInfo?.ad_block" :data="pageInfo?.ad_block" class="wrap1144" />
    <Section8 v-if="pageInfo?.contest && pageInfo?.contest_is_visible" :data="pageInfo?.contest" />
  </section>
</template>

<style lang="scss" scoped>
.home {
  &__banner {
    padding-left: 64px;
    padding-right: 64px;
    margin-bottom: 90px;

    @include max-media(1280) {
      padding-left: 0;
      padding-right: 0;
    }

    @include max-media(767) {
      margin-bottom: 75px;
    }
  }
}
</style>
